<template>
  <div class="container">
    <nav class="nav">
      <ul>
        <li
          v-for="(nav, index) in navs"
          :key="index"
          :class="{ active: nav.active }"
        >
          <router-link v-if="nav.isChild" :to="nav.name">{{
            nav.title
          }}</router-link>
          <a v-else @click="goTo(nav.name)">{{ nav.title }}</a>
          <aside v-if="nav.num">{{ nav.num }}</aside>
        </li>
      </ul>
    </nav>
    <div class="main">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navs: [
        {
          title: "审核管理",
          name: "project-audit",
          path:"/project-audit",
          isChild: 1,
          num: 0,
          active: false,
        },
        {
          title: "项目管理",
          name: "project-manage",
          path:"/project-manage",
          isChild: 1,
          num: 0,
          active: false,
        },
        {
          title: "审核设置",
          name: "audit-settings",
          path:"/audit-settings",
          isChild: 1,
          num: 0,
          active: false,
        },
      ],
    };
  },



  mounted () {
    this.initNav();

  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        this.initNav();
      },
      // 深度观察监听
      deep: true
    },

  },

  methods: {
    goTo (name) {
      let routeData = this.$router.resolve({ name: name, });
      window.open(routeData.href, '_blank');

    },

    initNav () {
      console.log("this.$route.path",this.$route.path);

      var has=false;
      this.navs.forEach((item) => {
        if(item.path == this.$route.path){
          item.active=true;
          has=true;
        }else {
          item.active=false;
          //item.active = (item.path == this.$route.path || this.$route.path.includes(item.name) ? true : false);

        }
      });

      if(has==false){
          this.navs.forEach((item) => {
          item.active = (item.path == this.$route.path || this.$route.path.includes(item.name) ? true : false);

        });
      }

      console.log("this.navs",this.navs);
      this.$forceUpdate();

    },

    changeActive (nav) {
      var that = this;
      var has=false;
      this.navs.forEach((item) => {
        if (item.name == nav.name) {
          item.active = true;
          has=true;
        } else {
          item.active = false;
        }

      });

      if(has==false){
          this.navs.forEach((item) => {
          item.active = (item.path == this.$route.path || this.$route.path.includes(item.name) ? true : false);
        });
      }

    },


  }
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
}

.nav {
  width: 150px;
  min-height: calc(100vh - 160px);
  border-radius: 6px;
  margin-right: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  ul {

    .active{
          background-color: #4c71db;
          color:#ffffff;

          a{
            color: #fff;
          }
      }
    li {
      position: relative;
      a {
        padding: 0 24px;
        line-height: 60px;
        // line-height: 72px;
        display: block;
        // font-size: 22px;
        &.router-link-active {
          background-color: #4c71db;
          color: #fff;
        }
      }

    
      aside {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: #ff4e4e;
        border-radius: 10px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }
    }
  }
}
.main {
  width: 980px;
  border-radius: 6px;
}
.container {
  padding: 20px;

}
</style>
